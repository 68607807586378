/* About Default Styles
 * ---------------------
 * default.css is used to establish general rules, browser reset, or admin user interface styles.
 * Browser Reset
 * -------------
 * For a more complete reset, go to http://developer.yahoo.com/yui/reset/ */
* {
  margin: 0;
  padding: 0;
}

/* Clear Floats Without Structural Markup
 * ---------------------------------------
 * http://www.positioniseverything.net/easyclearing.html */
.clearfix {
  display: inline-block;
}
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

/* Hides from IE-mac \ */
* html .clearfix {
  height: 1%;
}

.clearfix {
  display: block;
}

/* End hide from IE-mac */
/* clear classes */
.clearleft, .clearl, .cleft {
  clear: left;
}

.clearright, .clearr, .cright {
  clear: right;
}

.clear, .clearboth, .clearall {
  clear: both;
}

/* Float classes */
.floatleft, .fleft, .floatl {
  float: left;
  margin: 0 10px 5px 0;
}

.floatright, .fright, .floatr {
  float: right;
  margin: 0 0 5px 10px;
}

/* "Skip to Main Content" link
 * ---------------------------
 * This link useful for both visually impaired people and those with mobility impairments
 * who cannot use a mouse. http://www.webaim.org/techniques/css/invisiblecontent/ */
#skip a:link, #skip a:hover, #skip a:visited {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
#skip a:active, #skip a:focus {
  position: static;
  width: auto;
  height: auto;
}

/* bug fix with views 2 edit links. */
div.view div.views-admin-links {
  width: auto;
}

/* Rollover edit links for blocks. */
div.block {
  position: relative;
}
div.block .edit {
  display: none;
  position: absolute;
  right: -20px;
  top: -5px;
  z-index: 40;
  padding: 3px 8px 0;
  font-size: 10px;
  line-height: 16px;
  background-color: white;
  border: 1px solid #cccccc;
  /*let's get fancy! */
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-box-shadow: 0 1px 3px #888888;
  -webkit-box-shadow: -1px 1px 2px #666666;
}
div.block .edit a {
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
}
div.block:hover .edit {
  display: block;
}